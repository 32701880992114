@import "./Base.scss";

.PageElement {
    margin: 16px;
    height: auto;

    @media only screen and (min-width: 900px) {
        margin-left: 10%;
        margin-right: 10%;
    }

    @media only screen and (min-width: 1188px) {
        width: 1000px;
    }
}

.ColoredPSection {
    background-color: $background_light_white;

    @media only screen and (min-width: 1188px) {
        width: 1000px;
    }

    p {
        font-family: "Helvetica Neue";
        font-weight: 400;
        text-align: center;
        margin-top: 8%;
        margin-left: 8%;
        margin-right: 8%;

        size: 14px;
        line-height: 21px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            size: 20px;
            line-height: 30px;
        }
    }

    .SecondP {
        font-weight: 700;
        margin-top: 4%;
        margin-bottom: 8%;
    }

    img {
        width: 100%;
        height: 228px;
        object-fit: cover;
        border-radius: 2px;

        @media only screen and (min-width: 900px) {
            height: 639px;
        }
    }
}

.TextPart {
    @media only screen and (min-width: 900px) {
        margin-left: 16px;
        margin-right: 16px;
    }


    h2 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 36px;
            line-height: 36px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    h3 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 0px;
        color: $primary_color;
    }

    h4 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 16px;
        margin-top: 0px;
        line-height: 16px;
        color: $primary_color;
    }

    ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;

        @media only screen and (min-width: 900px) {
            columns: 1;
            -webkit-columns: 1;
            -moz-columns: 1;
            padding-left: 11px;
        }
    }

    li {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 0px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
        }
    }

    p {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 0px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
        }
    }
}

.MarginLeft {
    @media only screen and (min-width: 900px) {
        margin-left: 20%;
    }
}

.MarginRight {
    @media only screen and (min-width: 900px) {
        margin-right: 20%;
    }

}

.ImagePart {
    height: 100%;
    width: 100%;
    object-fit: cover;
}