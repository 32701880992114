@import "./Base.scss";

.SingleProjectContainer {

    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 900px) {}

    h2 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 20px;
        color: $primary_color;
        text-align: left;
        margin-bottom: 0px;
        line-height: 36px;

        @media only screen and (min-width: 900px) {
            font-size: 32px;
        }
    }

    p {
        text-align: left;
        font-family: "Helvetica Neue";
        font-weight: 400;
        line-height: 19.88px;
        font-size: 14px;
        margin: 16px;

        @media only screen and (min-width: 900px) {
            text-align: justify;
            font-size: 16px;
        }
    }

    ul {
        font-family: "Helvetica Neue";
        font-weight: 400;
        line-height: 19.88px;
        font-size: 14px;
        margin-right: 16px;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
        }
    }

    .TopContainerPart {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-left: 16px;
        margin-right: 16px;

        @media only screen and (min-width: 900px) {
            flex-direction: row;
            align-items: flex-start;

            margin-left: 50px;
        }

        .ProjectInfoSection {
            width: 100%;
            margin: 16px;

            @media only screen and (min-width: 900px) {
                width: 40%;
            }



            .ulSplit {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;

                @media only screen and (min-width: 900px) {
                    columns: 1;
                    -webkit-columns: 1;
                    -moz-columns: 1;
                }
            }

            .EnumerationDiv {
                height: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                margin-left: 40px;

                @media only screen and (min-width: 900px) {
                    margin-left: 0px;
                }

                h5 {
                    position: absolute;
                    left: -40px;
                    font-family: "DM Serif Display";
                    font-weight: 400;
                    font-size: 16px;

                    @media only screen and (min-width: 900px) {
                        left: -40px;
                        font-size: 20px;
                    }

                }
            }

            .ForSaleDivAddon {
                width: 77px;
                background-color: red;

                h4 {
                    font-family: "Helvetica Neue";
                    font-weight: 300;
                    color: $background_color;
                    font-size: 12px;
                    margin-top: 17px;
                }
            }

            .ProjectNameDivAddon {
                width: 118px;

                h4 {
                    font-family: "Helvetica Neue";
                    font-weight: 500;
                    color: $primary_color;
                    font-size: 12px;
                }
            }

        }

        .ProjectImageSection {
            width: 100%;
            margin: 16px;
            height: 537px;

            @media only screen and (min-width: 900px) {
                width: 60%;
                margin: 16px;
                height: 537px;
            }
        }
    }

}

.ImageSliderContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    .ImageSlider {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;

        transition: ease 800ms;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .SliderButtonContainer {
        position: absolute;
        display: flex;
        flex-direction: row;
        right: 0px;
        bottom: 0px;

        height: 64px;
        width: 129px;

        overflow: hidden;

        button {
            height: 100%;
            width: 49%;
            background-color: $background_color;
            border: none;

            font-size: 30px;
            font-family: "Helvetica Neue";
            font-weight: 500;
            color: $primary_color;
        }

        .buttonGoRight {
            margin-left: 3px;
        }

        .buttonTransparent {
            opacity: 60%;
            filter: blur(1.2px);

            color: rgba(0, 0, 0, 0.9);

            img {
                opacity: 100%;
                filter: blur(0);
            }
        }
    }

}