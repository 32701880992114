@import "./Base.scss";

.ServiceSection {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media only screen and (min-width: 900px) {
        margin-top: 30px;
        margin-bottom: 30px;

        flex-direction: column;
        align-items: flex-start;
    }

    h2 {
        color: $primary_color;
        font-size: 24px;
        font-family: "Helvetica Neue";
        font-weight: 400;

        margin-bottom: 5px;

        @media only screen and (min-width: 900px) {
            font-size: 40px;
            margin-top: 10px;
            margin-right: 40px;
        }
    }

    h3 {
        color: $primary_color;
        font-size: 20px;
        font-family: "DM Serif Display";
        font-weight: 400;
        text-align: center;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 0px;

        @media only screen and (min-width: 900px) {
            font-size: 36px;
            text-align: left;
            margin-left: 0px;
            margin-bottom: 10px;
        }
    }

    p {
        color: $primary_color;
        font-size: 12px;
        font-family: "Helvetica Neue";
        font-weight: 400;
        text-align: center;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
            text-align: left;
            width: 80%;
        }
    }

    img {
        width: 100%;
        object-fit: contain;

        @media only screen and (min-width: 900px) {
            max-height: 374px;
            height: 100%;
            float: right;
        }
    }

    a {
        color: $primary_color;
        font-size: 10px;
        font-weight: 500;
        font-family: "Helvetica Neue";

        @media only screen and (min-width: 900px) {
            font-size: 16px;
            margin-top: 20px;
            align-self: flex-start;
        }
    }
}

.LeftSection {
    @media only screen and (min-width: 900px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
}

.RightSection {
    height: 100%;

    @media only screen and (min-width: 900px) {
        align-items: flex-start;
        justify-content: center;
    }
}