@import "./Base.scss";

.ImageScrollbarContainer {
    width: 100%;
    margin-top: 20px;
    margin-left: 1%;
    margin-right: 1%;

    white-space: nowrap;
    transition: ease 1000ms;

    .ImageContainer {
        display: inline-block;
        width: 340px;
        height: 460px;
        margin-left: 6px;
        margin-right: 6px;

        img {

            border-radius: 4px;
        }
    }
}


.PagingContainer {
    height: 34px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 34.72px;
        height: 34.72px;
        border: none;
        background-color: $background_color;
        color: $primary_color;

        font-size: 30px;
        font-family: "DM Serif Display";
        font-weight: 700;

        margin-left: 2px;
        margin-right: 2px;
    }

    .Dissabled {
        color: $primary_color_transparent;
        opacity: 0.4;
    }
}