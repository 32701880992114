@import "./Base.scss";

.MrayButton {
    background-color: $background_color;
    color: $primary_color;
    border-color: $primary_color;
    width: 108px;
    height: 32px;
    font-size: 10px;
    font-family: "Helvetica Neue";
    margin-top: 20px;

    @media only screen and (min-width: 900px) {
        width: 175px;
        height: 56px;
        font-size: 16px;
    }
}