@import "./Base.scss";

.FooterContainer {
    // height: 278px;
    height: 180px;
    clear: both;

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $background_light_white;

    @media only screen and (min-width: 900px) {
        // height: 66px;
    }

    .logo {
        width: 100.31px;
        height: 57px;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 30px;

        @media only screen and (min-width: 900px) {
            width: 98px;
            height: 56px;
        }
    }

    .FooterMenuItems {
        margin: 30px;
        line-height: 25px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;

        .MenuItem {
            margin-left: 18px;
            margin-right: 18px;

            width: auto;

            a {
                font-family: "Helvetica Neue";
                color: $primary_color;
                font-weight: 400;
                font-size: 10px;
                text-decoration: none;
                width: 130px;
                text-align: center;

                @media only screen and (min-width: 900px) {
                    font-size: 14px;
                }
            }

            a:hover {
                text-decoration: underline;
            }

            .selectedMenuItem {
                text-decoration: underline;
            }
        }

        .BreakFlexLine {
            flex-basis: 100%;
            height: 0;
        }
    }

    .SocialMediaIconsBar {
        // margin-top: 60px;
        display: flex;
        flex-direction: row;

        @media only screen and (min-width: 900px) {
            display: none;
        }

        img {
            margin: 10px;
            width: 20px;
            height: 20px;
        }
    }

    .CopyrightBar {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: center;

        h5 {
            font-family: "DM Sans";
            font-weight: 400;
            color: $primary_color_transparent;
            font-size: 8px;
            margin-right: 100px;

            @media only screen and (min-width: 900px) {
                font-size: 12px;
                margin-right: 250px;
            }
        }

        a {
            font-family: "DM Sans";
            font-weight: 400;
            color: $primary_color_transparent;
            font-size: 8px;
            align-self: center;
            margin-left: 10px;
            text-decoration: none;

            @media only screen and (min-width: 900px) {
                font-size: 12px;
            }
        }
    }
}