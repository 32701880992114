@import "./Base.scss";

.HeaderContainer {
    height: 86px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media only screen and (min-width: 900px) {
        height: 66px;
    }

    .HorizontalMenuItems {
        height: 100%;
        display: flex;
        justify-content: center;
        // background-color: aquamarine;
        gap: 25px;


        @media only screen and (min-width: 400px) {
            width: 100%;
        }

        @media only screen and (min-width: 900px) {
            width: 90%;
        }

        @media only screen and (min-width: 1440px) {
            width: 1193px;
        }
    }

    .HorizontalMenuItem {
        display: none;

        @media only screen and (min-width: 900px) {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-width: 14.1%;
            // width: 14.286%;
        }
    }

    a {
        font-family: "Helvetica Neue";
        color: $primary_color;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        width: 130px;
        text-align: center;
    }

    a:hover {
        text-decoration: underline;
    }

    .selectedMenuItem {
        text-decoration: underline;
    }
}

.HeaderContainerDropdown {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;

    .MenuItems {
        margin-top: 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0px;
        gap: 24px;
    }

    @media only screen and (min-width: 900px) {
        display: none;
    }

    a {
        font-family: "Helvetica Neue";
        color: $primary_color;
        font-weight: 400;
        font-size: 16px;
        text-decoration: none;
        width: 130px;
        text-align: center;
    }

    a:hover {
        text-decoration: underline;
    }

    .selectedMenuItem {
        text-decoration: underline;
    }
}

.logo {
    width: 79.19px;
    height: 45px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 9px;

    @media only screen and (min-width: 900px) {
        margin-top: 15px;
    }
}

.MenuTitle {
    margin-top: 25px;
    color: $primary_color;
    font-weight: 400;
    font-family: "DM Serif Display";
    font-size: 24px;
}

.SideButtonContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 12px;

    .toggleButton {
        @media only screen and (min-width: 900px) {
            display: none;
        }

        width: 24px;
        height: 24px;
        margin: 6px;

        // left: 300;
        background-color: $background_color;
        border: $background_color;
    }

    .toggleButtonImg {
        height: 12px;
        width: 18px;
    }

}

.languageButton {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 24px;
    height: 24px;
    // top: 20px;
    // right: 70px;
    // left: 300;
    background-color: $background_color;

}