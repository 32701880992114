@import "./Base.scss";

.AboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1188px) {
        width: 1188px;
    }

    h2 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 50px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 64px;
        }
    }

    h4 {
        margin-top: 0px;
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 16px;
        font-style: italic;
        text-align: center;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 32px;
        }
    }

    p {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 14px;
        color: $primary_color;
        text-align: center;

        margin-left: 16px;
        margin-right: 16px;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
            width: 80%;
        }
    }

    .Quote {
        font-family: "DM Serif Display";
        font-style: italic;
        font-size: 20px;

        @media only screen and (min-width: 900px) {
            font-size: 32px;
            width: 76%;
        }
    }

    img {
        width: 96%;
        // height: 100px;

    }
}