@import "./Base.scss";

.HomeTitleContainer {
    position: relative;
    height: 563px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;

    @media only screen and (min-width: 900px) {
        height: 802px;
        margin-bottom: 40px;
    }

    @media only screen and (min-width: 1188px) {
        width: auto;
    }

    .HomeTitleContainerImage {
        display: block;
        height: 100%;
        // width: 100%;
    }

    p {
        margin-top: 432px;
        color: white;
        position: absolute;
        font-size: 24px;
        text-align: center;
        margin-left: 17px;
        margin-right: 17px;
        font-family: "DM Serif Display";

        @media only screen and (min-width: 660px) {
            margin-left: 10%;
            margin-right: 10%;
        }


        @media only screen and (min-width: 900px) {
            margin-top: 608px;
            font-size: 48px;
        }

        @media only screen and (min-width: 1188px) {
            width: 1000px;
        }
    }
}

.SigleContainerAbout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 9px;
    overflow: hidden;

    @media only screen and (min-width: 900px) {
        align-items: self-start;
        margin-left: 50px;
        margin-right: 50px;
    }


    .TitleSectionAbout {
        margin-top: 40px;

        @media only screen and (min-width: 900px) {
            margin-top: 0px;
        }
    }

    h1 {
        @media only screen and (min-width: 900px) {
            margin-top: 15px;
        }
    }

    h2 {
        text-align: center;
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 0px;

        @media only screen and (min-width: 900px) {
            text-align: left;
            font-size: 54px;
        }
    }

    h3 {
        text-align: center;
        font-style: italic;
        font-family: "DM Serif Display";
        font-weight: 400;
        margin-top: 3px;
        font-size: 16px;

        @media only screen and (min-width: 900px) {
            text-align: left;
            font-size: 32px;
        }
    }

    h4 {
        text-align: center;
        font-style: normal;
        font-family: "Helvetica Neue";
        font-weight: 400;
        margin-top: 3px;
        font-size: 16px;

        @media only screen and (min-width: 900px) {
            text-align: left;
        }
    }

    h5 {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
    }

    h6 {
        // font-style: italic;
        font-family: "Kalam";
        font-weight: 400;
        font-size: 14px;
        margin-top: 0px;
    }

    p {
        text-align: center;
        font-family: "Helvetica Neue";
        line-height: 19.88px;
        font-size: 14px;
        margin: 7px;

        @media only screen and (min-width: 900px) {
            text-align: justify;
            font-size: 16px;
        }
    }

    img {
        height: 322px;

        @media only screen and (min-width: 900px) {
            height: 524px;
        }
    }

    .OwnerImg {
        height: 265px;
        background-color: $background_color;

        @media only screen and (min-width: 900px) {
            height: 487px;
        }
    }
}

.VillaOliveContainer {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 50px;
    overflow: hidden;


    @media only screen and (min-width: 900px) {}

    @media only screen and (min-width: 1188px) {
        width: 1188px;
    }

    h2 {
        font-size: 24px;
        font-family: "DM Serif Display";
        font-weight: 400;

        @media only screen and (min-width: 900px) {
            font-size: 54px;
        }
    }

    p {
        font-size: 14px;
        font-family: "Helvetica Neue";
        font-weight: 400;
        text-align: center;
        margin-left: 10%;
        margin-right: 10%;

        @media only screen and (min-width: 900px) {
            font-size: 20px;
            margin-left: 25%;
            margin-right: 25%;
        }
    }
}