@import "./Base.scss";


.BaseContainer {
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .FormContainer {
        width: 200px;

        @media only screen and (min-width: 270px) {
            width: 250px;
        }

        @media only screen and (min-width: 422px) {
            width: 393px;
        }

        @media only screen and (min-width: 1100px) {
            width: 529px;
        }

        .EmailContainer {
            display: flex;
            flex-direction: row;

            h4 {
                font-family: "Helvetica Neue";
                font-weight: 400;
                font-size: 14px;
                color: $primary_color;

                @media only screen and (min-width: 900px) {
                    font-size: 20px;
                }
            }

            .KeySide {
                margin-right: 20px;
            }
        }

        h4 {
            font-family: "Helvetica Neue";
            font-weight: 600;
            font-size: 14px;
            color: $primary_color;

            @media only screen and (min-width: 900px) {
                font-size: 16px;
            }
        }

        form {
            display: flex;
            flex-direction: column;

            input {
                height: 43px;
                width: 180px;
                margin-top: 10px;
                padding-left: 16px;

                border: 1px solid $light_graycolor;
                background-color: $background_color;

                @media only screen and (min-width: 270px) {
                    width: 230px;
                }

                @media only screen and (min-width: 422px) {
                    width: 373px;
                }

                @media only screen and (min-width: 900px) {
                    height: 57px;
                }

                @media only screen and (min-width: 1100px) {
                    width: 510px;
                }


            }

            input:focus {
                border: 1px solid $primary_color;
            }

            ::placeholder {
                color: $gray_color;
            }

            .InvalidInput {
                border-color: red;
            }

            textarea {
                min-height: 86px;
                border: 1px solid $light_graycolor;
                background-color: $background_color;
                margin-top: 10px;
                padding: 16px;

                resize: vertical;
            }

            button {
                height: 50px;
                width: 100%;
                margin-top: 15px;

                background-color: $brown_color;
                border: none;
                color: $background_color;
                font-size: 14px;
                font-family: "Helvetica Neue";
                font-weight: 800;

                @media only screen and (min-width: 900px) {
                    height: 72px;
                }
            }

            .BtnDissabled {
                background-color: $gray_color;
            }

        }
    }

    .HorizontalLine {
        height: 1px;
        background-color: $light_graycolor;
        width: 100%;
        margin-bottom: 40px;
    }
}


.RightSideImageContainer {
    display: flex;
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px;

    margin-top: 32px;
    height: 94%;

    img {
        width: 200px;

        @media only screen and (min-width: 270px) {
            width: 250px;
        }

        @media only screen and (min-width: 422px) {
            width: 393px;
        }

        @media only screen and (min-width: 900px) {
            width: 90%;
            height: 100%;
            object-fit: cover;
        }
    }
}