@import "./Base.scss";

.TwoPartsComponentReverse {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    // margin-top: 40px;


    @media only screen and (min-width: 900px) {
        flex-direction: row-reverse;
    }

    @media only screen and (min-width: 1188px) {
        width: 1188px;
    }

    .LeftPart {
        position: relative;
        width: 50%;
        display: flex;

        @media only screen and (max-width: 900px) {
            width: 100%;
        }
    }

    .RightPart {
        position: relative;
        width: 50%;

        @media only screen and (max-width: 900px) {
            width: 100%;
        }
    }


}

.AddColor {
    background-color: $background_light_white;
}