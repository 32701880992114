@font-face {
    font-family: 'Helvetica Neue';
    /* IE9 Compat Modes */
    src: url('../fonts/helveticaneue-light.eot');
    /* Safari, Android, iOS */
    src: url('../fonts/HelveticaNeue Light.ttf') format('ttf'),
        /* Pretty Modern Browsers */
        url('../fonts/HelveticaNeue-Light.woff') format('woff'),
        /* Super Modern Browsers */
        url('../fonts/helveticaneue-light.woff2') format('woff2'),
        /* Legacy iOS */
        url('../fonts/helveticaneue-light.svg#Helvetica Neue') format('svg');
}

@font-face {
    font-family: 'DM Serif Display';
    /* IE9 Compat Modes */
    src: url('../fonts/DMSerifDisplay-Regular.eot');
    /* Safari, Android, iOS */
    src: url('../fonts/DMSerifDisplay-Regular.ttf') format('ttf'),
        /* Pretty Modern Browsers */
        url('../fonts/DMSerifDisplay-Regular.woff') format('woff'),
        /* Super Modern Browsers */
        url('../fonts/DMSerifDisplay-Regular.woff2') format('woff2'),
        /* Legacy iOS */
        url('../fonts/DM Serif Display Regular.svg#DM Serif Display') format('svg');
}

@font-face {
    font-family: 'Kalam';
    /* IE9 Compat Modes */
    src: url('../fonts/Kalam.eot');
    /* Safari, Android, iOS */
    src: url('../fonts/Kalam.ttf') format('ttf'),
        /* Pretty Modern Browsers */
        url('../fonts/Kalam.woff') format('woff'),
        /* Super Modern Browsers */
        url('../fonts/Kalam.woff2') format('woff2'),
        /* Legacy iOS */
        url('../fonts/Kalam.svg#Kalam') format('svg');
}

body {
    margin: 0;
    padding: 0;
}

.PageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // position: relative;
}

.PageElement {
    margin: 16px;
    width: 95%;

    @media only screen and (min-width: 900px) {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    @media only screen and (min-width: 1188px) {
        width: 1188px;
    }
}

.OnlyAtBigScreen {
    display: none;

    @media only screen and (min-width: 900px) {
        display: block;
    }
}

.OnlyAtSmallScreen {
    display: block;

    @media only screen and (min-width: 900px) {
        display: none;
    }
}



// primary colors
$primary_color: #2F240F;
$brown_color: #67583B;
$background_color: white;
$background_light_white: #F9F7F5;
$primary_color_transparent: #2F240F4D;
$light_graycolor: #E6E6E6;
$gray_color: #989898;
// fonts
$font_dm_serif_display: "DM Serif Display";
$font_helvetica_neue: "HelveticaNeue";

.TextColorLightWhite {
    color: $background_light_white;
}

.TextColorWhite {
    color: $background_color;
}

.LightWhiteBackground {
    background-color: $background_light_white;
}

.LightWhiteBackgroundOnBigScreen {
    background-color: $background_color;

    @media only screen and (min-width: 900px) {
        background-color: $background_light_white;
    }


}

.ExpandToWholePage {
    min-height: 82vh;
}

.TitleSection {
    margin-top: 5px;


    @media only screen and (min-width: 900px) {
        margin-top: 40px;
    }

    h2 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 36px;
        color: $primary_color;
        text-align: center;
        margin-bottom: 0px;
        line-height: 36px;

        @media only screen and (min-width: 900px) {
            font-size: 64px;
        }
    }

    h3 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 20px;
        font-style: italic;
        color: $primary_color;
        text-align: center;
        line-height: 20px;

        @media only screen and (min-width: 900px) {
            font-size: 32px;
        }
    }

    h4 {
        font-family: "Helvetica Neue";
        font-weight: 700;
        font-size: 14px;
        font-style: italic;
        color: $primary_color;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
        line-height: 20px;

        @media only screen and (min-width: 900px) {
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            margin-left: 11%;
            margin-right: 11%;
        }
    }

    p {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 14px;
        color: $primary_color;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;

        @media only screen and (min-width: 900px) {
            font-size: 20px;
            line-height: 30px;
            margin-top: 45px;
            margin-left: 20%;
            margin-right: 20%;
        }
    }


}

.AlignCenterOnSmall {
    text-align: center;

    @media only screen and (min-width: 900px) {
        text-align: left;
    }
}

.AlignCenterOnBig {
    text-align: left;

    @media only screen and (min-width: 900px) {
        text-align: center;
    }
}