@import "./Base.scss";

.MyDropdown {
    position: absolute;
    top: 40px;
    right: 70px;
    width: 104px;

    // z-index: 30;

    @media only screen and (min-width: 900px) {
        width: 120px;
    }

    @media only screen and (min-width: 1188px) {}

    .DropdownButton {
        width: 100%;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 2px;

        background-color: $background_color;

        @media only screen and (min-width: 900px) {
            height: 43px;
        }
    }

    .DropdownSelector {
        margin-top: 3px;

        background-color: $background_color;

        border-radius: 2px;

        .DropDownOption {
            width: 100%;
            height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media only screen and (min-width: 900px) {
                height: 43px;
            }
        }

        .DropDownOption:hover {
            background-color: $background_light_white;
        }
    }

    img {
        height: 24px;
        width: 24px;
        margin-left: 12%;
    }

    label {
        font-family: "Helvetica Neue";
        font-size: 14px;
        color: $primary_color;
        margin-left: 10px;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
        }
    }

    h5 {
        margin: 0px;
        padding: 2px;
        height: 11px;
    }
}



.Invisible {
    display: none;
}