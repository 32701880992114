@import "./Base.scss";

.SigleContainerOwner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 9px;
    overflow: hidden;

    @media only screen and (min-width: 900px) {
        align-items: self-start;
        margin-left: 50px;
        margin-right: 50px;
    }


    .TitleSection {
        margin-top: 40px;

        @media only screen and (min-width: 900px) {
            margin-top: 0px;
            width: 100%;
        }
    }

    h1 {
        @media only screen and (min-width: 900px) {
            margin-top: 15px;
        }
    }

    h2 {
        text-align: center;
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 0px;

        @media only screen and (min-width: 900px) {
            text-align: left;
            font-size: 54px;
        }
    }

    h3 {
        text-align: center;
        font-style: italic;
        font-family: "DM Serif Display";
        font-weight: 400;
        margin-top: 3px;
        font-size: 16px;

        @media only screen and (min-width: 900px) {
            text-align: left;
            font-size: 32px;
        }
    }

    h4 {
        text-align: center;
        font-style: normal;
        font-family: "Helvetica Neue";
        font-weight: 400;
        margin-top: 3px;
        font-size: 16px;

        @media only screen and (min-width: 900px) {
            text-align: left;
        }
    }

    h5 {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0px;
    }

    h6 {
        // font-style: italic;
        font-family: "Kalam";
        font-weight: 400;
        font-size: 14px;
        margin-top: 0px;
    }

    p {
        text-align: center;
        font-family: "Helvetica Neue";
        line-height: 19.88px;
        font-size: 14px;
        margin: 7px;

        @media only screen and (min-width: 900px) {
            text-align: justify;
            font-size: 16px;
        }
    }

    img {
        height: 322px;

        @media only screen and (min-width: 900px) {
            height: 524px;
        }
    }

    .OwnerImg {
        height: 265px;
        background-color: $background_color;

        @media only screen and (min-width: 900px) {
            height: 487px;
        }
    }

    .Signature {
        width: 180px;
        height: 60px;
        margin-top: 10px;
    }
}