@import "./Base.scss";

.TabsContainer {
    height: 49px;
    width: 100%;

    button {
        width: 50%;
        height: 100%;
        background-color: $background_color;
        border: none;
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 12px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 15px;
        }
    }
}

.UnderlineBar {
    width: 100%;
    height: 2px;
    background-color: $light_graycolor;

    .Underline {
        width: 50%;
        height: 100%;
        background-color: $gray_color;

        transition: ease 1000ms;
    }
}

.TabWrapper {
    overflow: hidden;
    width: 100%;
}

.TabContent {
    width: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: row;
    transition: ease 1000ms;

    .LeftContent {
        flex-shrink: 0;
        width: 100%;
    }

    .RightContent {
        flex-shrink: 0;
        width: 100%;
    }
}