@import "./Base.scss";

.ServicesSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1188px) {
        width: 1188px;
    }

    h2 {
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 50px;
        color: $primary_color;

        @media only screen and (min-width: 900px) {
            font-size: 64px;
            margin-left: 10%;
            margin-right: 10%;
            text-align: center;
            line-height: 69.12px;
            ;
        }
    }

    p {
        font-family: "Helvetica Neue";
        font-weight: 400;
        font-size: 14px;
        color: $primary_color;
        text-align: center;

        margin-left: 16px;
        margin-right: 16px;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
            width: 40%;
        }
    }
}