@import "./Base.scss";

.OurServicesContainer {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;

    @media only screen and (min-width: 900px) {}

    @media only screen and (min-width: 1188px) {
        width: 1188px;
    }

    h4 {
        color: $primary_color;
        font-size: 12px;
        font-family: "Helvetica Neue";
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: 40px;
        text-align: center;

        @media only screen and (min-width: 900px) {
            font-size: 16px;
        }
    }

    h1 {
        margin-top: 0px;
        margin-bottom: 10px;
        color: $primary_color;
        font-family: "DM Serif Display";
        font-weight: 400;
        font-size: 24px;


        @media only screen and (min-width: 900px) {
            font-size: 57px;
        }
    }

    p {
        color: $primary_color;
        margin-top: 0px;
        margin-bottom: 30px;
        margin-left: 19px;
        margin-right: 19px;
        text-align: center;
        font-size: 14px;
        font-family: "Helvetica Neue";
        font-weight: 400;

        @media only screen and (min-width: 500px) {
            width: 70%;
        }

        @media only screen and (min-width: 600px) {
            width: 60%;
        }

        @media only screen and (min-width: 900px) {
            font-size: 20px;
        }
    }

    .CardsContainer {

        @media only screen and (min-width: 200px) {
            width: 200px;
        }

        @media only screen and (min-width: 300px) {
            width: 300px;
        }

        @media only screen and (min-width: 500px) {
            width: 361;
        }

        @media only screen and (min-width: 900px) {
            width: 764px;
        }

        @media only screen and (min-width: 1188px) {
            width: 1188px;
        }


        .SingleCard {
            float: left;
            width: 100%;
            height: 191px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (min-width: 900px) {
                width: 50%;
                height: 320px;
            }

            @media only screen and (min-width: 1188px) {
                width: 33.3%;
            }

            .VisibleCard {
                background-color: $background_light_white;

                border: 0.5px solid;
                border-color: $primary_color_transparent;
                border-radius: 8px;

                height: 95%;
                width: 97.5%;

                display: flex;
                justify-content: start;
                align-items: center;
                flex-direction: column;

                @media only screen and (min-width: 900px) {
                    height: 97.5%;
                }

                h2 {
                    font-family: "Helvetica Neue";
                    font-weight: 400;
                    font-size: 16px;
                    color: $primary_color;
                    margin-left: 6%;
                    margin-right: 6%;
                    text-align: center;

                    @media only screen and (min-width: 900px) {
                        font-size: 30px;
                    }
                }

                h3 {
                    font-family: "Helvetica Neue";
                    font-weight: 400;
                    font-size: 12px;
                    color: $primary_color;
                    margin-left: 10%;
                    margin-right: 10%;
                    margin-top: 1px;
                    text-align: center;

                    @media only screen and (min-width: 900px) {
                        font-size: 18px;
                    }
                }

                img {
                    width: 48px;
                    height: 48px;
                    color: $primary_color;
                    margin-top: 3%;

                    @media only screen and (min-width: 900px) {
                        margin-top: 7%;
                    }
                }

                a {
                    color: $primary_color;
                    position: absolute;
                    margin-top: 155px;
                    font-family: "Helvetica Neue";
                    font-weight: 500;
                    font-size: 12px;

                    @media only screen and (min-width: 900px) {
                        margin-top: 270px;
                        font-size: 16px;
                    }

                }

            }
        }
    }
}